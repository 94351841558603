var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var NV,PV,QV,Nka,UV,VV,WV,Pka,XV,YV,ZV,aW,bW,cW,dW,eW,fW,hW,iW,jW,kW,lW,oW,pW,Oka;NV=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Uk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.me(c)}),a)};
$CLJS.OV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Se.g($CLJS.DC,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ue(t,$CLJS.PE(x,function(){return function(z){return $CLJS.Lk.l(NV(z),$CLJS.wB,$CLJS.H([$CLJS.Ei]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),e($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.PE(u,function(){return function(v){return $CLJS.Lk.l(NV(v),$CLJS.wB,$CLJS.H([$CLJS.Ei]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
PV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cf,a],null)],null);for(var c=$CLJS.Cf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.eg.j(v,$CLJS.hf.h(function(V,Z,ha,ra,Na){return function(zb){var Pa=$CLJS.I(zb,0,null);zb=$CLJS.I(zb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.be.g(Na,Pa),zb],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.be.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.gm($CLJS.Rs,k)):m}else return c}};QV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Hd(b,$CLJS.J.g(c,2))};$CLJS.RV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Tk.g($CLJS.xE,$CLJS.hd)),$CLJS.dQ.h(a));return PV($CLJS.Lk.l(a,$CLJS.IN,$CLJS.H([$CLJS.sV])),function(c){return QV($CLJS.tB,b,c)})};
$CLJS.SV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Tk.g($CLJS.FE,$CLJS.hd)),$CLJS.eG.h(a));return PV($CLJS.Lk.l(a,$CLJS.IN,$CLJS.H([$CLJS.sV])),function(c){return QV($CLJS.eG,b,c)})};Nka=function(a){function b(d){return $CLJS.jf(c,$CLJS.H([$CLJS.IN.h(d)]))}function c(d){return $CLJS.ee($CLJS.cF.h(d),$CLJS.jf(b,$CLJS.H([$CLJS.vL.h(d)])))}return $CLJS.n($CLJS.TV.h(a))?$CLJS.Ue(!0):$CLJS.fh(b(a))};
UV=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ue(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.EA(m,Nka(C));f=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(S,V,Z,ha,ra,Na){return function eb(Pa,Za){try{if($CLJS.zd(Za)&&3===$CLJS.D(Za))try{var Sa=$CLJS.F(Za,0);if($CLJS.he(Sa,$CLJS.pG))try{var mb=$CLJS.F(Za,1);if($CLJS.n($CLJS.yP.h(mb)))try{var Ja=$CLJS.F(Za,1);if(null!=Ja?Ja.C&256||$CLJS.Bc===Ja.mf||(Ja.C?0:$CLJS.$a($CLJS.wb,
Ja)):$CLJS.$a($CLJS.wb,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.yP,$CLJS.IV);if($CLJS.n($CLJS.Te(ha)(bc))){var $d=$CLJS.J.g(Ja,$CLJS.yP);$CLJS.F(Za,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Na),": no join named ",$CLJS.Mh.l($CLJS.H([$d]))].join("")],null)}throw $CLJS.Y;}catch(qd){if(qd instanceof Error){var Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;
}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)return $CLJS.FV(eb,Pa,Za);throw Id;}throw qd;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Cf,$CLJS.Lk.l(C,$CLJS.IN,$CLJS.H([Oka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};VV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);WV=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Pka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);XV=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
YV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);ZV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.$V=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);aW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
bW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);cW=new $CLJS.r(null,"refs","refs",80480079,null);dW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);eW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);fW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.gW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);hW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);iW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);jW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);kW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
lW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.mW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.TV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.nW=new $CLJS.M(null,"filters","filters",974726919);oW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);pW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.qW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Oka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(dW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.gW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Gl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LQ,new $CLJS.h(null,1,[$CLJS.Ss,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kV],null)],null)],null));$CLJS.X(iW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null));
$CLJS.X(VV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.bo,1],null),iW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.OV},WV,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.rk,$CLJS.ui,$CLJS.Si,$CLJS.nj,$CLJS.Ck,$CLJS.ti,$CLJS.kk,$CLJS.qk],[$CLJS.wV,XV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[cW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.OV)?$CLJS.OV.H:null]))],null)],null));
$CLJS.X(jW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.bo,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.OV},WV,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.rk,$CLJS.ui,$CLJS.Si,$CLJS.nj,$CLJS.Ck,$CLJS.ti,$CLJS.kk,$CLJS.qk],[$CLJS.wV,XV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[cW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.OV)?$CLJS.OV.H:null]))],null)],null));$CLJS.X(kW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.OF],null),$CLJS.ik,$CLJS.Zj],null)],null));
$CLJS.X(hW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.bo,1],null),kW],null));
var rW=$CLJS.EA(function(a){a=$CLJS.A($CLJS.RV(a));if($CLJS.n(a)){var b=$CLJS.FA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Mh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.SV(a));if($CLJS.n(a)){var b=$CLJS.FA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(lW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,2,[$CLJS.xt,"Valid references for a single query stage",$CLJS.zt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return rW(a)}],null),$CLJS.Te(rW)],null));
$CLJS.X($CLJS.qW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.$V],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.dG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),jW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nW,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),hW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TV,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.NQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.xt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Te($CLJS.mE($CLJS.jP,$CLJS.TV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lW],null)],null));$CLJS.X(YV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)],null)],null));
$CLJS.X(ZV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null)],null)],null)],null));$CLJS.X(bW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZV],null)],null));
$CLJS.X(eW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Te($CLJS.EA($CLJS.jP,$CLJS.TV))],null)],null));$CLJS.X(fW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,$CLJS.gW,$CLJS.$V],null));
$CLJS.X(Pka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.Ej],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$V,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qW],null)],null)],null)],null));
$CLJS.X(pW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.Ej],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$V,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bW],null)],null)],null)],null));$CLJS.X(aW,eW);
$CLJS.X(oW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,2,[$CLJS.xt,"Valid references for all query stages",$CLJS.zt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return UV.h?UV.h(a):UV.call(null,a)}],null),$CLJS.Te(UV)],null));
$CLJS.X($CLJS.rL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oW],null)],null));
$CLJS.X($CLJS.RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.mW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.fL,$CLJS.kL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rL],null)],null)],null),$CLJS.Gka],null));