var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Iz=function(){},Jz=function(a){return $CLJS.J.g($CLJS.xy,a).value},Ada=function(a){var b=Jz(a);return $CLJS.n(function(){var c=Jz($CLJS.Oy);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.tz:$CLJS.n(function(){var c=Jz($CLJS.Ny);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.Dz:$CLJS.n(function(){var c=Jz($CLJS.Ly);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.wz:$CLJS.n(function(){var c=Jz($CLJS.Fy);return $CLJS.Ok.g?
$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.Cz:$CLJS.n(function(){var c=Jz($CLJS.My);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.Ez:$CLJS.n(function(){var c=Jz($CLJS.Iy);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.zz:$CLJS.n(function(){var c=Jz($CLJS.Cy);return $CLJS.Ok.g?$CLJS.Ok.g(c,b):$CLJS.Ok.call(null,c,b)}())?$CLJS.Az:$CLJS.Bz},Kz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Lz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Hz,c))].join(""),$CLJS.H(["color:black"]))],null)},Mz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Hz,$CLJS.Fz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Hz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},Bda=function(a){function b(c,d){return d>=c}a=Jz(a);if(b(Jz($CLJS.Oy),a))return"error";if(b(Jz($CLJS.Ny),a))return"warn";if(b(Jz($CLJS.Ly),a))return"info";b(Jz($CLJS.Fy),a);return"log"},Nz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Dy),e=$CLJS.J.g(c,$CLJS.Pt);b=$CLJS.J.g(c,$CLJS.Py);e=Bda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Oz=function Oz(a,b){for(;;){if($CLJS.E.g($CLJS.sz,b))return Lz(a,", ",$CLJS.Bz);if($CLJS.E.g($CLJS.uz,b))return Kz(a);if(b instanceof $CLJS.M)return Lz(a,b,$CLJS.wz);if(b instanceof $CLJS.r)return Lz(a,b,$CLJS.Cz);if("string"===typeof b)return Lz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.vz);if($CLJS.zf(b)){var d=Kz(function(){var f=a,k=$CLJS.Eb(b);return Oz.g?Oz.g(f,k):Oz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Oz.g?Oz.g(d,e):Oz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Lz(d,"{",$CLJS.Gz),d=$CLJS.fb(Oz,d,$CLJS.ff($CLJS.sz,b)),Lz(d,"}",$CLJS.Gz);if($CLJS.xd(b))return d=a,d=Lz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.xz),d=Lz(d,"{",$CLJS.Gz),d=$CLJS.fb(Oz,d,$CLJS.ff($CLJS.sz,b)),Lz(d,"}",$CLJS.Gz);if($CLJS.vd(b))return d=a,d=Lz(d,"#{",$CLJS.Gz),d=$CLJS.fb(Oz,d,$CLJS.ff($CLJS.uz,b)),Lz(d,
"}",$CLJS.Gz);if($CLJS.zd(b))return d=a,d=Lz(d,"[",$CLJS.Gz),d=$CLJS.fb(Oz,d,$CLJS.ff($CLJS.uz,b)),Lz(d,"]",$CLJS.Gz);if(b instanceof $CLJS.Rf)d=Lz(a,"#queue ",$CLJS.xz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Lz(d,"(",$CLJS.xz),d=$CLJS.fb(Oz,d,$CLJS.ff($CLJS.uz,b)),Lz(d,")",$CLJS.xz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Iz,b)):$CLJS.$a(Iz,b))d=Lz(a,"#atom ",$CLJS.xz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=Lz(a,"#uuid ",$CLJS.xz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Lz(a,"#js ",$CLJS.xz),e=$CLJS.fb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Lz(a,"#js ",$CLJS.xz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Lz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.yz)}}};$CLJS.Cda=Nz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Pt);var b=$CLJS.J.g(a,$CLJS.Dy),c=$CLJS.J.g(a,$CLJS.Pi);$CLJS.J.g(a,$CLJS.Py);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Dda=Nz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Pt);a=$CLJS.J.g(b,$CLJS.Dy);var d=$CLJS.J.g(b,$CLJS.Pi);$CLJS.J.g(b,$CLJS.Py);b=Ada(c);d=Oz(Kz(Mz(Mz(Mz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Eda=Nz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Pt);var b=$CLJS.J.g(a,$CLJS.Dy),c=$CLJS.J.g(a,$CLJS.Pi);$CLJS.J.g(a,$CLJS.Py);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});