var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var A5,Hqa,Iqa,Jqa,C5,D5,E5,Kqa,Lqa,H5,F5,G5,I5,J5,Mqa,Nqa,Oqa,K5,Pqa,Qqa,L5,Rqa,Sqa;A5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=A5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Lk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Lk.g(a,b)}return a};
Hqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.im(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Se.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Iqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Hqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.B5=function(a){return $CLJS.FE.h($CLJS.NE(a))};
Jqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.dQ,$CLJS.A(b))?$CLJS.EW(d,$CLJS.DW(c)):d;return Iqa(a,b,function(f){return $CLJS.Df(function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.E.g($CLJS.B5(C),$CLJS.B5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.E.g($CLJS.B5(x),
$CLJS.B5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
C5=function(a,b,c,d){var e=$CLJS.FA(a,b);if($CLJS.n(e)){var f=$CLJS.B5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.eg.j($CLJS.Cf,$CLJS.Uk.h($CLJS.Tk.g($CLJS.ch([f]),$CLJS.B5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null))&&$CLJS.Qe(function(l){return $CLJS.MY(l,$CLJS.tB)},e)?null:e;if($CLJS.y(e))return $CLJS.aM(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IN,$CLJS.yL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.hi($CLJS.RE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Hy,$CLJS.PW,$CLJS.yL,$CLJS.FA(a,b),$CLJS.QW,$CLJS.FA(a,$CLJS.sd(b)),$CLJS.JL,d,$CLJS.RW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IN,$CLJS.ZE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.BX.v(a,$CLJS.sd(b),$CLJS.Lk,k):A5(a,b)}return a};
D5=function(a,b){var c=$CLJS.a4.g(a,b),d=$CLJS.lh(0,$CLJS.D(c));return $CLJS.gf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dQ],null)],null),function(){return function k(f){return new $CLJS.ne(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.F(c,x);$CLJS.ZE.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,x,$CLJS.ZE],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.ZE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,u,$CLJS.ZE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ne(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,x,$CLJS.yL],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,u,$CLJS.yL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
E5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.vG),k=$CLJS.J.g(e,$CLJS.zR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.BH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.vG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.zR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.gm($CLJS.Rs,$CLJS.TR.h($CLJS.KW(a,b)))))};
Kqa=function(a,b,c,d){c=E5(a,b,c);return $CLJS.n(c)?$CLJS.VW.l(a,b,$CLJS.BX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.TR,c,2,1],null),$CLJS.Tk.g(function(e){return $CLJS.x1($CLJS.Dl,e)},$CLJS.kl),d])):a};Lqa=function(a,b,c){c=E5(a,b,c);return $CLJS.n(c)?$CLJS.VW.l(a,b,C5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TR],null),$CLJS.FA($CLJS.KW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TR,c],null)),b])):a};
H5=function(a,b,c,d,e,f){var k=$CLJS.VW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.B5(e);return $CLJS.Rk.g(a,k)?$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.he(u,$CLJS.dQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.DW(e);K=F5.W?F5.W(k,b,c,$CLJS.tB,$CLJS.N,K):F5.call(null,k,b,c,$CLJS.tB,$CLJS.N,K);return G5.v?G5.v(K,b,c,l):G5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.he(u,$CLJS.eG))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=F5.W?F5.W(k,b,c,$CLJS.eG,$CLJS.N,l):F5.call(null,k,b,c,$CLJS.eG,$CLJS.N,l);return G5.v?G5.v(S,b,c,l):G5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.dG))return new $CLJS.P(null,
1,5,$CLJS.Q,[G5.v?G5.v(k,b,c,l):G5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.ZE))return new $CLJS.P(null,1,5,$CLJS.Q,[G5.v?G5.v(k,b,c,l):G5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){var z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.he(C,$CLJS.IN))try{var G=$CLJS.F(t,2);if($CLJS.he(G,$CLJS.ZE))return new $CLJS.P(null,1,5,$CLJS.Q,[G5.v?G5.v(k,b,c,l):G5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Cf,d)))):k};
F5=function(a,b,c,d,e,f){var k=$CLJS.KW(a,b),l=$CLJS.jf(function(m){var t=$CLJS.FA(k,m);return $CLJS.n(t)?$CLJS.fm(function(u){return $CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function C(x,z){try{if($CLJS.zd(z)&&3===$CLJS.D(z))try{var G=$CLJS.F(z,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(z,1);if($CLJS.td(e)||$CLJS.w1($CLJS.fh(e),$CLJS.fh(K)))try{var S=$CLJS.F(z,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var V=
Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)return $CLJS.FV(C,x,z);throw V;}throw Z;}}($CLJS.Cf,u))))},t):null},$CLJS.H([D5(a,b)]));return $CLJS.fb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return H5(m,b,
c,u,t,function(v,x,z){return C5(v,x,z,b)})},a,l)};G5=function(a,b,c,d){b=$CLJS.D2(c,b);if($CLJS.n(b)){var e=$CLJS.KW(c,b);c=$CLJS.Re(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.WK),l=$CLJS.J.g(f,$CLJS.i1);return $CLJS.E.g($CLJS.DZ,k)&&$CLJS.E.g(d,l)?$CLJS.PZ.h(f):null},$CLJS.Q0.j(c,b,e));return $CLJS.n(c)?F5(a,b,a,$CLJS.pG,$CLJS.N,c):a}return a};
I5=function(a,b,c,d,e){var f=$CLJS.wW.h(c),k=$CLJS.KW(a,b);c=$CLJS.BH(function(t){var u=$CLJS.FA(k,t);if($CLJS.n(u)){var v=$CLJS.B5(f);return $CLJS.n($CLJS.Re($CLJS.Tk.j($CLJS.ch([v]),$CLJS.FE,$CLJS.hd),u))?t:null}return null},D5(a,b));var l=(d=$CLJS.E.g($CLJS.NG,d))?$CLJS.wW.h(e):null;e=d?function(t,u,v){return Jqa(t,u,v,l)}:function(t,u,v){return C5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dG],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Kqa(a,b,f,$CLJS.hm($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zR,$CLJS.vG],null))):m?Lqa(a,b,f):a;return $CLJS.n(c)?H5(a,b,a,c,f,e):a};J5=function(a,b){return $CLJS.MY(a,$CLJS.pG)&&$CLJS.E.g($CLJS.I1(a),b)};Mqa=function(a,b,c){return function k(e,f){try{if(J5(f,b))return $CLJS.G3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.dX(k,e,f);throw l;}throw m;}}($CLJS.Cf,a)};
Nqa=function(a,b,c){var d=$CLJS.IN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.FA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.cF],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Rk.g(e,c):b)){var f=$CLJS.NW();$CLJS.C3(f,$CLJS.hf.g($CLJS.cF,d));c=f(c);return Mqa($CLJS.aM(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,b,$CLJS.cF],null),c),e,c)}return a};
Oqa=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.ch([c]);return"string"===typeof c?$CLJS.Tk.g(e,$CLJS.cF):e}();return $CLJS.Re(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.gm($CLJS.Rs,$CLJS.IN.h($CLJS.KW(a,b))))};
K5=function(a,b,c,d){b=$CLJS.ku.g($CLJS.fh($CLJS.Q0.j(b,c,$CLJS.KW(b,c))),$CLJS.fh($CLJS.Q0.j(a,c,$CLJS.KW(a,c))));return $CLJS.fb(function(e,f){return $CLJS.Se.N(F5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Pqa=function(a,b,c){a=K5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.yP,$CLJS.d_.h(d)],null),$CLJS.Zi.h(d)],null)});c=$CLJS.D2(a,c);return $CLJS.n(c)?K5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.N,$CLJS.PZ.h(d)],null)}):a};
Qqa=function(a,b,c){return $CLJS.Fd(c)?$CLJS.FA($CLJS.KW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,c,$CLJS.cF],null)):$CLJS.xd(c)?$CLJS.cF.h(c):c};L5=function(a,b,c,d){var e=Qqa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.VW)(a,b,function(k){var l=$CLJS.IN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.ME(k,$CLJS.IN,l)});return(0,$CLJS.VW)(f,b,function(k){return $CLJS.ZW(k,$CLJS.IN,function(l){return $CLJS.Vk.g(function(m){return $CLJS.O3(f,b,m)},l)})})}(),Pqa(c,a,b)):a};
Rqa=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function f(d,e){try{if(J5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.FV(f,d,e);throw k;}throw l;}}($CLJS.Cf,a))))};Sqa=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.N5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Ej.h(f),$CLJS.qL)?$CLJS.M5.j?$CLJS.M5.j(d,e,f):$CLJS.M5.call(null,d,e,f):I5(d,e,f,Sqa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Ej.h(f),$CLJS.qL)?$CLJS.O5.v?$CLJS.O5.v(d,e,f,k):$CLJS.O5.call(null,d,e,f,k):I5(d,e,f,$CLJS.NG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Tqa=function(){function a(d,e,f,k){f=Oqa(d,e,f);return $CLJS.n(f)?$CLJS.VW.l(d,e,Nqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.M5=function(){function a(d,e,f){try{return L5(d,e,f,function(u,v){return $CLJS.Fe($CLJS.d1(function(x){return!($CLJS.E.g($CLJS.cF.h(x),v)||Rqa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.km(k)),m=$CLJS.J.g(l,$CLJS.JL),t=$CLJS.J.g(l,$CLJS.Hy);l=$CLJS.J.g(l,$CLJS.QW);if($CLJS.E.g(t,$CLJS.PW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.O5=function(){function a(d,e,f,k){return null==k?$CLJS.M5.j(d,e,f):L5(d,e,f,function(l,m){return $CLJS.Vk.g(function(t){return $CLJS.E.g($CLJS.cF.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();