var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Kra,Lra,S7,T7,Mra;Kra=function(a){if("string"===typeof a)return $CLJS.nh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.nh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.mm(a));};$CLJS.Q7=function(a){return $CLJS.Xa(a)?$CLJS.Rz(a,$CLJS.H([$CLJS.yi,!0])):a};
Lra=function(a,b,c){var d=Kra(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.DA(d))/Math.log(10)));return $CLJS.Re(function(f){f*=e;return f>=d?f:null},$CLJS.gqa)};
$CLJS.R7=function(a,b){var c=$CLJS.l7(a);if($CLJS.n(c)){var d=$CLJS.KE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.FA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XN,$CLJS.mj,$CLJS.Pj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.bo),d=$CLJS.J.g(d,$CLJS.jk),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.rE),c=Lra(a,d,c),new $CLJS.h(null,3,[$CLJS.JE,c,$CLJS.LL,b,$CLJS.QL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.JE),new $CLJS.h(null,3,[$CLJS.JE,c,
$CLJS.LL,b,$CLJS.QL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};S7=function(a){var b=$CLJS.kA.h($CLJS.z1($CLJS.Z_(a)));b=b.h?b.h($CLJS.P7):b.call(null,$CLJS.P7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.E4.g(b,-1))&&$CLJS.td($CLJS.S5.g(b,-1))){if(b=$CLJS.Wk.j(b,$CLJS.vL,$CLJS.sd),!$CLJS.y($CLJS.vL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
T7=function(a,b){var c=S7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Lk.l(b,$CLJS.OW,$CLJS.H([$CLJS.UW]));var d=$CLJS.O0,e=d.j,f=a;var k=a;var l=$CLJS.f2(k,-1);k=$CLJS.n(l)?$CLJS.KW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.q2.v(a,-2,$CLJS.vW(b),d);if($CLJS.n(b))a=$CLJS.Wk.j(a,$CLJS.vL,$CLJS.sd);else return null}};
Mra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.l7(b))){var e=$CLJS.R7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.LL);f=$CLJS.J.g(f,$CLJS.QL);var k=$CLJS.s5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.e2($CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.e2($CLJS.$r,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.x3)(b,c)],null)}();return $CLJS.fb(function(e,f){return $CLJS.y3.j(e,-1,f)},
a,d)};$CLJS.$5.m(null,$CLJS.T6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.O6);b=$CLJS.J.g(b,$CLJS.SZ);return new $CLJS.h(null,3,[$CLJS.mj,$CLJS.T6,$CLJS.O6,a,$CLJS.SZ,b],null)});
$CLJS.Z5.m(null,$CLJS.T6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.f6),l=$CLJS.J.g(f,$CLJS.ML),m=S7(c),t=$CLJS.VW.l(m,-1,$CLJS.Lk,$CLJS.H([$CLJS.eG,$CLJS.dG,$CLJS.TR,$CLJS.qS,$CLJS.ZE]));d=$CLJS.fb(function(v,x){var z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.Si);z=$CLJS.J.g(z,$CLJS.Dj);return Mra(v,x,z)},
t,function(){return function z(x){return new $CLJS.ne(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Ad(K)){var S=$CLJS.lc(K),V=$CLJS.D(S),Z=$CLJS.qe(V);return function(){for(var ra=0;;)if(ra<V){var Na=$CLJS.kd(S,ra);Na=$CLJS.Wk.j(Na,$CLJS.Si,function(){return function(zb){return T7(c,zb)}}(ra,C,Na,S,V,Z,K,G,m,t,e,f,k,l));$CLJS.Rk.g($CLJS.WK.h($CLJS.Si.h(Na)),$CLJS.oL)&&Z.add(Na);ra+=1}else return!0}()?$CLJS.te($CLJS.ve(Z),z($CLJS.mc(K))):$CLJS.te($CLJS.ve(Z),null)}var ha=
$CLJS.A(K);ha=$CLJS.Wk.j(ha,$CLJS.Si,function(){return function(ra){return T7(c,ra)}}(C,ha,K,G,m,t,e,f,k,l));if($CLJS.Rk.g($CLJS.WK.h($CLJS.Si.h(ha)),$CLJS.oL))return $CLJS.ee(ha,z($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.id(k);return $CLJS.n(v)?$CLJS.BH(function(x){return $CLJS.E.g($CLJS.B5(x),v)},$CLJS.E4.g(m,-1)):null}();return $CLJS.fb(function(v,x){return $CLJS.y3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.A(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(u)],null);case "metric":return $CLJS.z3.g($CLJS.R.j($CLJS.dZ.h((0,$CLJS.HY)($CLJS.d2($CLJS.Q7(new $CLJS.h(null,2,[$CLJS.mj,$CLJS.MF,$CLJS.MF,$CLJS.Q7($CLJS.KZ.h($CLJS.c0(c,$CLJS.id(u))))],null)),$CLJS.mj,$CLJS.MF))),$CLJS.ZP,$CLJS.ZP.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());