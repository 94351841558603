var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var hV,iV,jV,lV,mV,nV,oV,pV,qV,rV;hV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);iV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);jV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.kV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);lV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);mV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);nV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
oV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);pV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);qV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);rV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(hV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.xt,"Valid template tag :widget-type"],null),$CLJS.jA],null),$CLJS.Xg($CLJS.cV)));$CLJS.X(lV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nk,$CLJS.LM,$CLJS.AN,$CLJS.JF,$CLJS.Zt,$CLJS.AB,$CLJS.GP],null));
$CLJS.X(oV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tE,$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.yE,$CLJS.Ms],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Gl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.JF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Ss,!0],
null),$CLJS.ik],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.LM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.mL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.fL],null)],null)],null));
$CLJS.X(rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.AN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hR,$CLJS.eL],null)],null)],null));$CLJS.X(qV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nk],null),$CLJS.RU));
$CLJS.X(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null)],null)],null));
$CLJS.X(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null)],null)],null));
$CLJS.X($CLJS.kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,$CLJS.yE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));